import React from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <div className='header dark'>
      <div className='container'>
        <nav id='navigation' className='navigation navigation-landscape'>
          <div className='nav-header'>
            <Link className='nav-brand text-logo my-2' to='/'>
              <img
                src='assets/img/original-logo-symbol.png'
                width={30}
                alt='some alt'
              />
              <h5 className='ms-1 my-0'>AV REALTIES</h5>
            </Link>
            <div className='nav-toggle' />
            <div className='mobile_nav'>
              <ul>
                {/* <li>
                  <Link
                    to='/'
                    data-bs-toggle='modal'
                    data-bs-target='#login'
                    className='text-dark opacity-75'
                  >
                    <span className='svg-icon svg-icon-2hx'>
                      <svg
                        width={35}
                        height={35}
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          opacity='0.3'
                          d='M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z'
                          fill='currentColor'
                        />
                        <path
                          d='M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z'
                          fill='currentColor'
                        />
                        <rect
                          x={7}
                          y={6}
                          width={4}
                          height={4}
                          rx={2}
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to='/submit-property' className='text-primary'>
                    <span className='svg-icon svg-icon-2hx'>
                      <svg
                        width={35}
                        height={35}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          opacity='0.3'
                          x={2}
                          y={2}
                          width={20}
                          height={20}
                          rx={10}
                          fill='currentColor'
                        />
                        <rect
                          x='10.8891'
                          y='17.8033'
                          width={12}
                          height={2}
                          rx={1}
                          transform='rotate(-90 10.8891 17.8033)'
                          fill='currentColor'
                        />
                        <rect
                          x='6.01041'
                          y='10.9247'
                          width={12}
                          height={2}
                          rx={1}
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    to='/'
                    className='text-primary'
                    data-bs-toggle='offcanvas'
                    data-bs-target='#offcanvasScrolling'
                    aria-controls='offcanvasScrolling'
                  >
                    <span className='svg-icon svg-icon-2hx'>
                      <svg
                        width={22}
                        height={22}
                        viewBox='0 0 16 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          y={6}
                          width={16}
                          height={3}
                          rx='1.5'
                          fill='currentColor'
                        />
                        <rect
                          opacity='0.3'
                          y={12}
                          width={8}
                          height={3}
                          rx='1.5'
                          fill='currentColor'
                        />
                        <rect
                          opacity='0.3'
                          width={12}
                          height={3}
                          rx='1.5'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div
            className='nav-menus-wrapper'
            style={{ transitionProperty: 'none' }}
          >
            <ul className='nav-menu'>
              <li className='active'>
                <Link to='/'>Home</Link>
              </li>
              {/* <li>
                <Link to='/listings-list-with-sidebar'>Listings</Link>
                <ul className='nav-dropdown nav-submenu'>
                  <li>
                    <Link to='/listings-list-with-sidebar'>Land</Link>
                  </li>
                  <li>
                    <Link to='/listings-list-with-sidebar'>Flats</Link>
                  </li>
                  <li>
                    <Link to='/listings-list-with-sidebar'>Villa</Link>
                  </li>
                  <li>
                    <Link to='/listings-list-with-sidebar'>Offices</Link>
                  </li>
                  <li>
                    <Link to='/listings-list-with-sidebar'>Warehouses</Link>
                  </li>
                  <li>
                    <Link to='/listings-list-with-sidebar'>Shops</Link>
                  </li>
                </ul>
              </li> */}
              <li>
                <Link to='/blog'>Blog</Link>
              </li>
              <li>
                <Link to='/contact'>Contact</Link>
              </li>
            </ul>
            <ul className='nav-menu nav-menu-social align-to-right'>
              {/* <li>
                <Link
                  to='/'
                  data-bs-toggle='modal'
                  data-bs-target='#login'
                  className='fw-medium text-muted-2'
                >
                  <span className='svg-icon svg-icon-2hx me-1'>
                    <svg
                      width={22}
                      height={22}
                      viewBox='0 0 18 18'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        opacity='0.3'
                        d='M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z'
                        fill='currentColor'
                      />
                      <path
                        d='M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z'
                        fill='currentColor'
                      />
                      <rect
                        x={7}
                        y={6}
                        width={4}
                        height={4}
                        rx={2}
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                  SignUp or SignIn
                </Link>
              </li> */}
              <li className='add-listing'>
                <Link
                  to='https://wa.me/+918830851524?text=Hi,%20I%20would%20like%20to%20connect'
                  className='btn btn-success'
                  title='Click here to connect with us'
                >
                  <span className='svg-icon svg-icon-muted svg-icon-2hx me-2'>
                    <svg
                      width='24px'
                      height='24px'
                      viewBox='0 0 24 24'
                      role='img'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <title>WhatsApp icon</title>
                      <path
                        d='M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                  Connect With Us
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}
